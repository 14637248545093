import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import {
    IUser,
    ICountry,
    ICity,
    ILanguage,
    IUserUpdateProps,
} from 'interfaces'
import { validationFormErrorsType } from 'utils/validators'
import { EMAIL_PUBLIC } from 'config/app'
import {
    Avatar,
    Button,
    Input,
    Radio,
    CustomSelect,
    CountryDataSelect,
    CityDataSelect,
    SvgResource,
} from 'components'
import { AppService } from 'services'
import { parseTpl, defaultDateFormat } from 'utils/helpers'

import styleForm from 'styles/modules/form.module.css'
import styleInput from 'components/Input/Input.module.css'
import style from './UserForm.module.css'

export type FormDataType = IUserUpdateProps

export type UserFormPropType = {
    data: FormDataType
    errors: validationFormErrorsType
    countries: ICountry[]
    country: ICountry
    languages: ILanguage[]
    language: ILanguage
    isSubmitting: boolean
    city?: ICity
    userMemberId?: IUser['member_id']
    userPhones?: IUser['user_phones']
    onChangePhoto: (data: File) => void
    onChangeCountry: (data: ICountry) => void
    onChangeLanguage: (data: ILanguage) => void
    onChangeCity: (data: ICity) => void
    onChange: (name: string, value: string) => void
    onSubmit: () => void
}

const UserForm: React.FC<UserFormPropType> = ({
    data = {},
    errors,
    countries = [],
    country,
    languages = [],
    language,
    isSubmitting,
    city,
    userMemberId,
    userPhones,
    onChangePhoto,
    onChangeCountry,
    onChangeLanguage,
    onChangeCity,
    onChange,
    onSubmit,
}) => {
    const { photo } = data

    const { t } = useTranslation()

    const profile: any = { user_phones: [] }

    const { avatar, isResizeAvatar } = useMemo(() => {
        const result = { avatar: '', isResizeAvatar: false }

        if (photo) {
            return photo.includes('http')
                ? { ...result, avatar: photo, isResizeAvatar: true }
                : { ...result, avatar: `data:image/png;base64,${photo}` }
        }

        return result
    }, [photo])

    const maxBirthDayRestrictions = useMemo(() => {
        const date = AppService.getDateMinimumAgeRegistration()
        return defaultDateFormat(date)
    }, [])

    const phoneDescText = useMemo(() => {
        return parseTpl(t('for_change_phone'), { '%s': '' }, {
            prefix: '',
            suffix: '',
        })
    }, [])

    const supportMailTo = useMemo(() => {
        return userMemberId
            ? `mailto:${EMAIL_PUBLIC}?subject=Change%20phone%20number%20please!&body=Member_id:%20${userMemberId}.%0ANew%20Phone%20number:%20`
            : ''
    }, [userMemberId])

    const handlerSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()
        onSubmit()
    }

    const handlerChangeFormData = ({ currentTarget }: React.SyntheticEvent<HTMLInputElement>) => {
        const { name, value } = currentTarget
        onChange(name, value)
    }

    const handlerChangePhoto = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = target
        const [file] = files || []

        onChangePhoto(file)
    }

    const handlerChangeCountry = (countryData: ICountry) => {
        onChangeCountry(countryData)
    }

    const handlerChangeLanguage = (id: number) => {
        const lang = languages.find((item) => item.id === id)

        if (lang) {
            onChangeLanguage(lang)
        }
    }

    const handlerChangeCity = (cityData: ICity) => {
        onChangeCity(cityData)
    }

    return (
        <form className={cn({ [styleForm.submitting]: isSubmitting })} onSubmit={handlerSubmit}>
            <div className={style.avatarField}>
                <label className={style.avatarWrap}>
                    <Input
                        classes={styleForm.inputFile}
                        styleType="clear"
                        type="file"
                        onChange={handlerChangePhoto}
                    />
                    <Avatar
                        width={180}
                        height={180}
                        src={avatar}
                        isResize={isResizeAvatar}
                        name={data.name}
                        surname={data.surname}
                    />
                    <div className={style.avatarText}>
                        {t('change_profile_photo')}
                    </div>
                </label>
            </div>

            <div className={cn(styleForm.row, styleForm.row_32)}>
                <label>
                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_gray)}>
                        {t('firstname')}
                    </div>
                    <Input
                        name="name"
                        placeholder={`${t('firstname')}*`}
                        classes={cn(style.formInput, { [styleForm.invalid]: errors.name })}
                        styleType="gray"
                        value={data.name}
                        onChange={handlerChangeFormData}
                    />
                </label>
                <div className={styleForm.error}>
                    {errors.name}
                </div>
            </div>

            <div className={cn(styleForm.row, styleForm.row_32)}>
                <label>
                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_gray)}>
                        {t('lastname')}
                    </div>
                    <Input
                        name="surname"
                        placeholder={`${t('lastname')}*`}
                        classes={cn(style.formInput, { [styleForm.invalid]: errors.surname })}
                        styleType="gray"
                        value={data.surname}
                        onChange={handlerChangeFormData}
                    />
                </label>
                <div className={styleForm.error}>
                    {errors.surname}
                </div>
            </div>

            <div className={cn(styleForm.row, styleForm.row_32)}>
                <label>
                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_gray)}>
                        {t('email')}
                    </div>
                    <Input
                        name="email"
                        inputMode="email"
                        placeholder={t('email')}
                        classes={cn(style.formInput, { [styleForm.invalid]: errors.email })}
                        styleType="gray"
                        value={data.email}
                        onChange={handlerChangeFormData}
                    />
                </label>
                <div className={styleForm.error}>
                    {errors.email}
                </div>
            </div>

            <div className={cn(styleForm.row, styleForm.row_32)}>
                <label>
                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_gray)}>
                        {t('borndate')}
                    </div>
                    <div className={styleForm.field}>
                        <Input
                            type="date"
                            name="birthDate"
                            placeholder={t('borndate')}
                            classes={cn(style.formInput, { [styleForm.invalid]: errors.birthDate })}
                            styleType="gray"
                            max={maxBirthDayRestrictions}
                            value={data.birthDate}
                            onChange={handlerChangeFormData}
                        />
                        <SvgResource
                            classes={cn(
                                styleForm.fieldIcon,
                                styleForm.fieldIcon_right,
                                style.formFieldIcon,
                            )}
                            resourceKey="wallet_icon_calendar_svg"
                            width={30}
                            height={30}
                        />
                    </div>
                </label>
            </div>

            <div className={cn(styleForm.row, styleForm.row_32)}>
                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_gray)}>
                    {t('city')}
                </div>
                <div className={cn(
                    styleForm.group,
                    styleForm.group_noSidePadding,
                    styleInput.gray,
                    { [styleForm.invalid]: errors.city },
                )}
                >
                    <CountryDataSelect
                        classes={cn(
                            styleForm.groupItem,
                            styleForm.groupItem_static,
                            styleForm.groupItem_minWidth,
                        )}
                        classesField={style.fieldSelect}
                        countries={countries}
                        selected={country}
                        isShowSelectArrow
                        onChange={handlerChangeCountry}
                    />

                    <CityDataSelect
                        classes={cn(
                            styleForm.groupItem,
                            styleForm.groupItem_static,
                            styleForm.groupItem_maxWidth,
                        )}
                        classesField={style.formSelectField}
                        selected={city}
                        countryId={country?.id}
                        countryLang={country?.lang}
                        onChange={handlerChangeCity}
                    />
                </div>
                <div className={styleForm.error}>
                    {errors.city}
                </div>
            </div>

            <div className={cn(styleForm.row, styleForm.row_32)}>
                <label>
                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_gray)}>
                        {t('Language')}
                    </div>
                    <CustomSelect.Select>
                        <CustomSelect.Field classes={cn(styleInput.gray, style.formSelectField)}>
                            {language.name}
                        </CustomSelect.Field>
                        <CustomSelect.Options>
                            {languages?.map((item) => (
                                <CustomSelect.Option
                                    id={item.id}
                                    key={item.id}
                                    onClick={handlerChangeLanguage}
                                >
                                    {item.name}
                                </CustomSelect.Option>
                            ))}
                        </CustomSelect.Options>
                    </CustomSelect.Select>
                </label>
            </div>

            <div className={cn(styleForm.row, styleForm.row_32)}>
                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_gray)}>
                    {t('gender')}
                </div>
                <div className={style.formFieldColumn}>
                    <Radio
                        classes={style.formRadio}
                        classesText={style.formRadioText}
                        name="gender"
                        text={t('female')}
                        value="0"
                        checked={data.gender !== null && Number(data.gender) === 0}
                        onChange={handlerChangeFormData}
                    />
                    <Radio
                        classes={style.formRadio}
                        classesText={style.formRadioText}
                        name="gender"
                        text={t('male')}
                        value="1"
                        checked={data.gender !== null && Number(data.gender) === 1}
                        onChange={handlerChangeFormData}
                    />
                </div>
            </div>

            {!!userPhones?.length && (
                <div className={cn(styleForm.row, styleForm.row_32)}>
                    {userPhones.map((item, index) => (
                        <div className={cn(styleForm.row, styleForm.row_24)} key={item.phone_number}>
                            <label>
                                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_gray)}>
                                    {t('phone')}
                                    {' '}
                                    {profile.user_phones.length > 1 && (
                                        index + 1
                                    )}
                                </div>
                                <Input
                                    disabled
                                    placeholder={t('phone')}
                                    classes={style.formInput}
                                    value={item.phone_number}
                                    styleType="gray"
                                />
                            </label>
                        </div>
                    ))}

                    <div className={style.formFieldDesc}>
                        {/* TODO будет использоваться категория службы поддержки id=11 */}
                        {phoneDescText}
                        {' '}
                        <a
                            className={style.descLink}
                            href={supportMailTo}
                        >
                            {t('to_support')}
                        </a>
                    </div>
                </div>
            )}

            <div className={cn(styleForm.row, styleForm.row_32)}>
                <label>
                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_gray)}>
                        ID
                    </div>
                    <Input
                        disabled
                        classes={style.formInput}
                        styleType="gray"
                        value={userMemberId}
                    />
                </label>
            </div>

            <div className={cn(styleForm.controls, styleForm.controls_64)}>
                <Button
                    classes={style.formButtonSubmit}
                    text={t('save')}
                    size="size44"
                    type="submit"
                />
            </div>
        </form>
    )
}

export default UserForm
