import {
    ILanguage,
    ICountry,
    IResource,
    ICityProps,
    ICity,
    ICurrency,
    IRatingRank,
} from 'interfaces'
import { AppMode } from 'enums'
import {
    CLIENT_STORAGE_LANGUAGES_KEY,
    CLIENT_STORAGE_COUNTRIES_KEY,
    CLIENT_STORAGE_RESOURCES_KEY,
    CLIENT_STORAGE_APP_ID_KEY,
    LANGUAGES_TIME_TO_UPDATE,
    COUNTRIES_TIME_TO_UPDATE,
    RESOURCE_TIME_TO_UPDATE,
    APP_ID_USER,
    APP_VERSION,
    REQUEST_HEADER_NAME_APP_ID,
} from 'config/app'
import { APP_MODE, API_URL } from 'config/api'
import { StorageService } from 'services'
import requestClient from 'utils/requestClient'

export type TDirectoryServiceError = {
    status: number
    type: string
    title: string // example: An error occurred
    detail: string // example: Not Found
}

type TResourceProps = {
    path: string
}

export type TCurrenciesProps = {
    countryId?: number
    applyTo?: 3 // 3 - Реальные валюты, используются в payout для вывода средств, оплаты заказов
    canWithdrawal?: boolean
}

export type TCitiesProps = ICityProps

export type TCitiesResponse = ICity[]

export type TCurrenciesResponse = ICurrency[]

/**
 * API directories
 */
class DirectoryService {
    static fetchLanguages(): Promise<{ data: ILanguage[] }> {
        const {
            date,
            languages,
        } = StorageService.getItem<{ date: number, languages: ILanguage[] }>(CLIENT_STORAGE_LANGUAGES_KEY) || {}

        return date && languages && (Date.now() - date) < LANGUAGES_TIME_TO_UPDATE
            ? Promise.resolve({ data: languages })
            : requestClient(API_URL.languages)
                .then(({ data }) => {
                    if (data) {
                        StorageService.setItem(CLIENT_STORAGE_LANGUAGES_KEY, {
                            date: Date.now(),
                            languages: data,
                        })
                    }
                    return { data }
                })
    }

    static fetchCountries(): Promise<{ data: ICountry[] }> {
        const {
            date,
            countries,
        } = StorageService.getItem<{ date: number, countries: ICountry[] }>(CLIENT_STORAGE_COUNTRIES_KEY) || {}

        return date && countries && (Date.now() - date) < COUNTRIES_TIME_TO_UPDATE
            ? Promise.resolve({ data: countries })
            : requestClient(API_URL.countries)
                .then(({ data }) => {
                    if (data) {
                        StorageService.setItem(CLIENT_STORAGE_COUNTRIES_KEY, {
                            date: Date.now(),
                            countries: data,
                        })
                    }
                    return { data }
                })
    }

    static fetchCities({ id, ...params }: TCitiesProps) {
        return requestClient<TCitiesResponse>(`${API_URL.cities}/${id}`, { params })
    }

    static fetchResources(): Promise<{ data: IResource[] }> {
        const {
            date,
            resources,
        } = StorageService.getItem<{ date: number, resources: IResource[] }>(CLIENT_STORAGE_RESOURCES_KEY) || {}

        return date && resources && (Date.now() - date) < RESOURCE_TIME_TO_UPDATE
            ? Promise.resolve({ data: resources })
            : requestClient<IResource[]>(API_URL.resources)
                .then(({ data }) => {
                    if (Array.isArray(data)) {
                        StorageService.setItem(CLIENT_STORAGE_RESOURCES_KEY, {
                            date: Date.now(),
                            resources: data,
                        })
                    }
                    return { data }
                })
    }

    static fetchResource({ path }: TResourceProps) {
        const appId = APP_MODE === AppMode.business
            ? StorageService.getItem<string>(CLIENT_STORAGE_APP_ID_KEY, true, 'session')
            : APP_ID_USER

        return fetch(path, {
            method: 'GET',
            headers: {
                [REQUEST_HEADER_NAME_APP_ID]: `${appId}/${APP_VERSION}`,
                cache: 'no-cache',
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.text()
                }
                return Promise.reject()
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }

    static fetchCurrencies(params: TCurrenciesProps = {}) {
        return requestClient<TCurrenciesResponse>(API_URL.currencies, { params })
    }

    static fetchRatingRanks() {
        return requestClient<IRatingRank[]>(API_URL.ratingRanks)
    }
}

export default DirectoryService
