import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { NoDataInfo } from 'components'
import style from './ReportsTableView.module.css'

export type TableConfiguration = {
    data: {
        labels: (string | number | JSX.Element)[]
        dataset: {
            data: (string | number | JSX.Element)[][]
        }
        footer?: (string | number | JSX.Element)[]
    }
}

type ReportsTableViewPropType = {
    config?: TableConfiguration
}

const ReportsTableView: React.FC<ReportsTableViewPropType> = ({ config }) => {
    const { t } = useTranslation()

    const data = useMemo(() => {
        return config ? config.data : { labels: [], dataset: { data: [] }, footer: undefined }
    }, [config])

    return (
        <div>
            <div className={style.header}>
                {data.labels.map((item) => item)}
            </div>
            <div className={style.body}>
                {!data.dataset.data.length && (
                    <NoDataInfo text={t('no_data')} />
                )}
                {data.dataset.data.map((row, index) => (
                    /* eslint-disable-next-line */
                    <div className={style.row} key={index}>
                        {row.map((item) => item)}
                    </div>
                ))}
            </div>
            {data.footer && (
                <div className={style.footer}>
                    <div className={style.row}>
                        {data.footer.map((item) => item)}
                    </div>
                </div>
            )}
        </div>
    )
}

export default ReportsTableView
