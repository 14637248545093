import React, { useState, useMemo } from 'react'
import {
    useHistory,
    useLocation,
    matchPath,
    generatePath,
} from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IStoreProfile } from 'interfaces'
import { TabsItemType } from 'components/Tabs/TabsItem/TabsItem'
import { APP_URL } from 'config/app'
import * as appSelectors from 'containers/App/app-selectors'
import {
    InfoBlock,
    Image,
    MenuList,
    Popover,
    Tabs,
} from 'components'
import style from './ShopNav.module.css'

type ShopNavPropType = {
    data?: IStoreProfile
    onClickShow?: () => void
    onClickEdit?: () => void
    onClickShare?: () => void
}

const ShopNav: React.FC<ShopNavPropType> = ({
    children,
    data,
    onClickShow,
    onClickEdit,
    onClickShare,
}) => {
    const { t } = useTranslation()
    const history = useHistory()
    const { pathname } = useLocation()

    const { forbidden } = useSelector(appSelectors.config)
    const { shopCatalogUserView } = useSelector(appSelectors.settings)

    const [isOpenMenu, setIsOpenMenu] = useState(false)

    const tabs = useMemo(() => {
        const result: TabsItemType[] = [{ id: 1, name: t('catalog') }]

        if (forbidden.kicks) {
            result.push({ id: 2, name: t('kiki'), disabled: true })
        }
        if (forbidden.cashback) {
            result.push({ id: 3, name: t('moderate_receipt_title'), disabled: true })
        }

        return result
    }, [])

    const activeTabId: number = useMemo(() => {
        if (matchPath(pathname, { path: APP_URL.shop, exact: true })) {
            return 1
        }
        if (matchPath(pathname, APP_URL.shopKicks)) {
            return 2
        }
        if (matchPath(pathname, APP_URL.shopCashback)) {
            return 3
        }
        return 0
    }, [pathname])

    const handlerChangeTab = (tabId: number) => {
        if (data) {
            switch (tabId) {
                case 1:
                    history.push(generatePath(APP_URL.shop, { id: data.id }))
                    break
                case 2:
                    history.push(generatePath(APP_URL.shopKicks, { id: data.id }))
                    break
                case 3:
                    history.push(generatePath(APP_URL.shopCashback, { id: data.id }))
                    break
                default:
                //
            }
        }
    }

    return (
        <InfoBlock classes={style.block}>
            <InfoBlock.Body classes={style.body}>
                <Popover
                    classes={style.menu}
                    isShowArrow={false}
                    isOpen={isOpenMenu}
                    position="bottom"
                    side="left"
                    trigger={<Popover.TriggerDots position="vertical" />}
                    onOpen={() => setIsOpenMenu(true)}
                    onClose={() => setIsOpenMenu(false)}
                >
                    <MenuList classes={style.action} type="large">
                        <MenuList.Item onClick={onClickShow} disabled={!onClickShow}>
                            {shopCatalogUserView ? (
                                t('edit')
                            ) : (
                                t('catalog_user_view')
                            )}
                        </MenuList.Item>
                        <MenuList.Item onClick={onClickEdit} disabled={!onClickEdit}>
                            {t('edit_store')}
                        </MenuList.Item>
                        <MenuList.Item onClick={onClickShare} disabled={!onClickShare}>
                            {t('link_menu_share')}
                        </MenuList.Item>
                    </MenuList>
                </Popover>
                <Image
                    classesWrap={style.imageWrap}
                    classesImage={style.image}
                    src={data?.logo_image}
                    width={80}
                    height={60}
                    resize={{ width: 80 }}
                />
                <div className={style.name}>
                    {data?.name}
                </div>
                {children}
            </InfoBlock.Body>
            <InfoBlock.Footer classes={style.footer}>
                <Tabs
                    classes={cn(style.tabs, { [style.tabs_spaceAround]: tabs.length > 1 })}
                    classesItem={style.tabsItem}
                    items={tabs}
                    active={activeTabId}
                    onChange={handlerChangeTab}
                />
            </InfoBlock.Footer>
        </InfoBlock>
    )
}

export default ShopNav
