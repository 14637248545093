import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IRootState, ITicketItem } from 'interfaces'
import { AppDispatch } from 'store'
import { ContentContainer } from 'layout'
import {
    PageHeader,
    Button,
    Modal,
    Loader,
    ErrorMsg,
} from 'components'
import { fetchCount } from 'containers/User/user-actions'
import * as userSelectors from 'containers/User/user-selectors'
import { SupportAction } from 'form-actions'
import { scrollTop } from 'utils/helpers'
import { useUserGUID } from 'hooks'
import { SupportItem } from './components'
import { fetchTickets } from './support-actions'
import style from './Support.module.css'

const Support: React.FC = () => {
    const { t } = useTranslation()
    const dispatch: AppDispatch = useDispatch()
    const guid = useUserGUID()

    const user = useSelector(userSelectors.user)
    const tickets = useSelector((state: IRootState) => state.tickets)

    const [ticketsError, setTicketsError] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [isOpenModalSupport, setIsOpenModalSupport] = useState(false)

    const handlerModalSupport = () => {
        setIsOpenModalSupport((prevState) => !prevState)
    }

    const handlerCompleteSupport = () => {
        setIsOpenModalSupport(false)
        setTicketsError('')
    }

    function fetchTicketsAction() {
        if (guid) {
            if (tickets.length) {
                setIsUpdating(true)
            } else {
                setIsLoading(true)
            }

            dispatch(fetchTickets({ guid }))
                .then((data) => {
                    if (!data?.length) {
                        setTicketsError(t('no_data'))
                    }
                })
                .catch(() => {
                    setTicketsError(t('update_error'))
                })
                .finally(() => {
                    if (tickets.length) {
                        setIsUpdating(false)
                    } else {
                        setIsLoading(false)
                    }
                })
        } else {
            setTicketsError(t('update_error'))
        }
    }

    function fetchCountAction() {
        dispatch(fetchCount({ counter: 'support_count' })).catch(() => {})
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        fetchTicketsAction()

        if (user) {
            fetchCountAction()
        }

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    return (
        <>
            <ContentContainer classes={style.content} size="three-quarters">
                <PageHeader
                    title={t('ui_support_create_ticket_title')}
                >
                    <Button
                        classes={style.headerAction}
                        size="size44"
                        text={t('ui_support_create_new_ticket')}
                        onClick={handlerModalSupport}
                    />
                </PageHeader>

                {isLoading && (
                    <Loader />
                )}

                {!isLoading && ticketsError && (
                    <ErrorMsg error={ticketsError} />
                )}

                <div className={cn(style.items, isUpdating ? style.updating : '')}>
                    {(tickets as ITicketItem[]).map((item) => (
                        <SupportItem data={item} key={item.id} />
                    ))}
                </div>
            </ContentContainer>

            <Modal
                isOpen={isOpenModalSupport}
                size="medium"
                onClose={handlerModalSupport}
            >
                <Modal.Header
                    isCloseButton
                    classes={style.modalFormHeader}
                    titlePos="left"
                    title={t('ui_support_create_ticket_title')}
                />

                <SupportAction
                    classes={{ body: style.modalFormBody, footer: style.modalFormFooter }}
                    guid={guid}
                    onComplete={handlerCompleteSupport}
                />
            </Modal>
        </>
    )
}

export default Support
