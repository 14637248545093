import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import {
    TAddStoreCatalogProps,
    TUpdateStoreCatalogProps,
    TAddStoreCatalogResponse,
    TUpdateStoreCatalogResponse,
    TRemoveStoreCatalogProps,
} from 'services/StoreService'
import { TAddStoreCatalogMutationFnError, TUpdateStoreCatalogMutationFnError } from 'containers/Store/hooks/useFetchStoreCatalog'
import { FormDataType, FieldName, formDataInitial } from 'forms/ShopFolderForm/ShopFolderForm'
import { REGEXP_DATA_IMAGE_BASE64 } from 'config/app'
import { useFetchStoreCatalogTree, useMutationStoreCatalog } from 'containers/Store/hooks'
import { ShopFolderForm } from 'forms'

type TCommonShopFolderProps = Pick<TAddStoreCatalogProps, 'storeId'> | TUpdateStoreCatalogProps

type ShopFolderActionPropType = {
    classesBody?: string
    classesFooter?: string
    shopFolderProps: TCommonShopFolderProps
    onPreview?: () => void
    onChange?: (data: FormDataType) => void
    onRemove?: (data: TUpdateStoreCatalogProps) => void
    onSuccess?: (data: TAddStoreCatalogResponse | TUpdateStoreCatalogResponse) => void
    onError?: (err: TAddStoreCatalogMutationFnError | TUpdateStoreCatalogMutationFnError) => void
}

const ShopFolderAction: React.FC<ShopFolderActionPropType> = ({
    classesBody,
    classesFooter,
    shopFolderProps,
    onPreview,
    onChange,
    onRemove = () => {},
    onSuccess,
    onError,
}) => {
    const { t } = useTranslation()

    const [initialValues, setInitialValues] = useState<FormDataType>({
        ...formDataInitial,
        ...shopFolderProps,
    })

    const [isCanDelete, setIsCanDelete] = useState(false)

    const {
        data: dataStoreCatalogTree,
    } = useFetchStoreCatalogTree({
        storeId: shopFolderProps.storeId,
        tree_only: true,
    })

    const { add: addStoreCatalog, update: updateStoreCatalog, remove: removeStoreCatalog } = useMutationStoreCatalog()

    const handlerSubmit = ({ id, photo, ...params }: FormDataType) => {
        const imageData = photo && REGEXP_DATA_IMAGE_BASE64.test(photo)
            ? photo.replace(REGEXP_DATA_IMAGE_BASE64, '')
            : undefined

        const folderParams: TAddStoreCatalogProps = {
            storeId: shopFolderProps.storeId,
            type: 'folder',
            photo: imageData,
            ...params,
        }

        if (id) {
            return updateStoreCatalogAction({ id, ...folderParams })
                .then(onSuccess)
                .catch(onError)
        }

        return addStoreCatalogAction(folderParams)
            .then(onSuccess)
            .catch(onError)
    }

    const handlerRemove = ({ id, ...params }: FormDataType) => {
        if (id) {
            removeStoreCatalogAction({ id })
                .then(() => {
                    onRemove({
                        id,
                        storeId: shopFolderProps.storeId,
                        type: 'folder',
                        ...params,
                    })
                })
                .catch(onError)
        }
    }

    function addStoreCatalogAction(params: TAddStoreCatalogProps) {
        return addStoreCatalog.mutateAsync(params)
    }

    function updateStoreCatalogAction(params: TUpdateStoreCatalogProps) {
        return updateStoreCatalog.mutateAsync(params)
    }

    function removeStoreCatalogAction(params: TRemoveStoreCatalogProps) {
        return removeStoreCatalog.mutateAsync(params)
    }

    useEffect(() => {
        if (dataStoreCatalogTree?.length) {
            const rootParentId = dataStoreCatalogTree[0].id

            setInitialValues((prevState) => {
                const data = 'id' in shopFolderProps
                    ? dataStoreCatalogTree.find((item) => item.id === shopFolderProps.id)
                    : undefined

                if (data) {
                    const {
                        name,
                        parent = rootParentId,
                        sort,
                        image,
                    } = data

                    return {
                        ...prevState,
                        name,
                        parent,
                        order: sort,
                        photo: image,
                    }
                }

                return { ...prevState, parent: rootParentId }
            })

            setIsCanDelete(() => {
                if ('id' in shopFolderProps && shopFolderProps.id) {
                    return !dataStoreCatalogTree.some((item) => item.parent === shopFolderProps.id)
                }
                return false
            })
        }
    }, [dataStoreCatalogTree])

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handlerSubmit}
            validate={(values) => {
                const errors = {}

                if (!values[FieldName.parent]) {
                    errors[FieldName.parent] = t('error_field_is_empty')
                }
                if (values[FieldName.name] === undefined || values[FieldName.name] === '') {
                    errors[FieldName.name] = t('error_field_is_empty')
                }

                return errors
            }}
            render={({ handleSubmit, submitting }) => (
                <ShopFolderForm
                    classesBody={classesBody}
                    classesFooter={classesFooter}
                    isSubmitting={submitting}
                    isCanDelete={isCanDelete}
                    catalog={dataStoreCatalogTree}
                    onPreview={onPreview}
                    onChange={onChange}
                    onRemove={handlerRemove}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default ShopFolderAction
