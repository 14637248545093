import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
    IRootState,
    ITicket,
    ITicketItem,
    ITicketMessageProps,
} from 'interfaces'
import { AppDispatch } from 'store'
import { ContentContainer, Block } from 'layout'
import {
    PageTitle,
    TicketStatusTag,
    UserMessageFormWrap,
    Loader,
    ErrorMsg,
} from 'components'
import { fetchTickets, fetchTicket } from 'containers/Support/support-actions'
import { fetchCount, deleteCount } from 'containers/User/user-actions'
import * as userSelectors from 'containers/User/user-selectors'
import { TicketMessageAction } from 'form-actions'
import { scrollTop } from 'utils/helpers'
import useUserGUID from 'hooks/useUserGUID'
import { TicketMessage } from './components'
import style from './Ticket.module.css'

const Ticket: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const { t, i18n } = useTranslation()
    const dispatch: AppDispatch = useDispatch()
    const guid = useUserGUID()
    const { language } = i18n

    const user = useSelector(userSelectors.user)
    const tickets = useSelector((state: IRootState) => state.tickets)
    const counts = useSelector((state: IRootState) => state.counts)

    const [ticketMessageProps] = useState<ITicketMessageProps>({
        id: Number(id),
        generated_user_id: guid,
        message: '',
    })
    const [isLoading, setIsLoading] = useState(false)
    const [ticketError, setTicketError] = useState('')

    const ticket = useMemo(() => {
        return id ? tickets.find((item) => item.id === Number(id)) : undefined
    }, [tickets])

    const messages = useMemo(() => {
        return (ticket as ITicket)?.messages?.length
            ? (ticket as ITicket).messages.sort((a, b) => (a.id > b.id ? 1 : -1))
            : []
    }, [ticket])

    function getTicket(ticketList: ITicketItem[]) {
        const ticketGUID = getTicketGUID(ticketList)
        fetchTicketAction(ticketGUID)
    }

    function getTicketGUID(ticketList: ITicketItem[]) {
        const currentTicket = id ? ticketList.find((item) => item.id === Number(id)) : undefined
        return currentTicket?.generated_user_id
    }

    function fetchTicketsAction() {
        if (guid) {
            setIsLoading(true)
            dispatch(fetchTickets({ guid }))
                .then((data) => {
                    if (data?.length) {
                        getTicket(data)
                    } else {
                        setTicketError(t('no_data'))
                    }
                })
                .catch(() => {
                    setTicketError(t('update_error'))
                })
                .finally(() => {
                    setIsLoading(false)
                })
        } else {
            setTicketError(t('update_error'))
        }
    }

    function fetchTicketAction(ticketGUID?: string) {
        if (ticketGUID) {
            setIsLoading(true)
            dispatch(fetchTicket({
                id,
                guid: ticketGUID,
                lang: user ? user.language.iso_code : language,
            }))
                .then((data) => {
                    if (!data) {
                        setTicketError(t('no_data'))
                    } else if (counts?.support_count) {
                        resetCountAction()
                    }
                })
                .catch(() => {
                    setTicketError(t('update_error'))
                })
                .finally(() => {
                    setIsLoading(false)
                })
        } else {
            setTicketError(t('update_error'))
        }
    }

    function fetchCountAction() {
        dispatch(fetchCount({ counter: 'support_count' })).catch(() => {})
    }

    function resetCountAction() {
        dispatch(deleteCount({ counter: 'support_count' })).catch(() => {})
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        if (user) {
            fetchCountAction()
        }

        if (tickets.length) {
            getTicket((tickets as ITicketItem[]))
        } else {
            fetchTicketsAction()
        }

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    return (
        <ContentContainer size="half">
            <div className={style.header}>
                <PageTitle
                    classes={style.title}
                    title={ticket?.category.name}
                />

                <div className={style.status}>
                    <TicketStatusTag status={ticket?.status_id} />
                </div>
            </div>

            {isLoading && (
                <Loader />
            )}

            {!isLoading && ticketError && (
                <ErrorMsg error={ticketError} />
            )}

            {!!messages.length && (
                <Block classes={style.ticket}>
                    <div className={style.ticketItems}>
                        {messages.map((item) => (
                            <TicketMessage data={{ user, message: item }} key={item.id} />
                        ))}
                    </div>

                    <UserMessageFormWrap classes={style.formFieldWrap} user={user}>
                        <TicketMessageAction
                            ticketMessageProps={ticketMessageProps}
                        />
                    </UserMessageFormWrap>
                </Block>
            )}
        </ContentContainer>
    )
}

export default Ticket
