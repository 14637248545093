import React, { useMemo, useState } from 'react'
import {
    useParams,
    useHistory,
    useLocation,
    matchPath,
    generatePath,
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IBreadcrumb } from 'interfaces'
import { TUpdateStoreCatalogProps, TAddStoreCatalogResponse, TUpdateStoreCatalogResponse } from 'services/StoreService'
import { TAddStoreCatalogMutationFnError } from 'containers/Store/hooks/useFetchStoreCatalog'
import { FormDataType } from 'forms/ShopFolderForm/ShopFolderForm'
import { APP_URL, BREAKPOINTS } from 'config/app'
import { ContentContainer, SideBarContainer, Block } from 'layout'
import { useFetchStoreProfile, useInvalidateStoreCatalog } from 'containers/Store/hooks'
import { ShopFolderPreview } from 'containers/Shop/components'
import { PageHeader, Breadcrumbs, Modal } from 'components'
import { ShopFolderAction } from 'form-actions'
import { useWindowResize } from 'hooks'
import { showAlertNotify } from 'utils/helpers'
import style from './ShopFolder.module.css'

const ShopFolder: React.FC = () => {
    const { id, folderId } = useParams<{ id: string, folderId?: string }>()
    const { t } = useTranslation()
    const history = useHistory()
    const { pathname } = useLocation()
    const [windowWidth] = useWindowResize()

    const [shopId] = useState(Number(id))
    const [dataShopFolder, setDataShopFolder] = useState<FormDataType>()
    const [isOpenModalPreview, setIsOpenModalPreview] = useState(false)

    const { data: dataStoreProfile } = useFetchStoreProfile({
        id: shopId,
    }, {
        enabled: !Number.isNaN(shopId),
    })

    const { invalidate: invalidateStoreCatalog } = useInvalidateStoreCatalog()

    const isMobile = useMemo(() => {
        return windowWidth < BREAKPOINTS.tabletLandscape
    }, [windowWidth])

    const breadcrumbs = useMemo<IBreadcrumb[]>(() => {
        const parent = { id: 1, name: t('stores'), url: APP_URL.stores }
        const shop = dataStoreProfile
            ? { id: 2, name: dataStoreProfile.name, url: generatePath(APP_URL.shop, { id: dataStoreProfile.id }) }
            : undefined
        const folderCreate = { id: 3, name: t('screen_pm_folder_create_title') }
        const folderEdit = { id: 3, name: t('screen_pm_folder_edit_title') }

        if (isMobile) {
            if (matchPath(pathname, { path: APP_URL.shopFolder, exact: true })) {
                return [folderCreate]
            }
            if (matchPath(pathname, APP_URL.shopFolderEdit)) {
                return [folderEdit]
            }

            return []
        }

        if (shop && matchPath(pathname, { path: APP_URL.shopFolder, exact: true })) {
            return [parent, shop, folderCreate]
        }
        if (shop && matchPath(pathname, APP_URL.shopFolderEdit)) {
            return [parent, shop, folderEdit]
        }

        return []
    }, [pathname, isMobile, dataStoreProfile])

    const shopFolderProps = useMemo(() => {
        return {
            storeId: shopId,
            id: folderId ? Number(folderId) : undefined,
        }
    }, [shopId, folderId])

    const handlerClickBack = () => {
        history.push(generatePath(APP_URL.shop, { id }))
    }

    const getParentCatalogUrl = (parent: number) => {
        const catalogId = folderId ? parent : undefined

        return catalogId
            ? generatePath(APP_URL.shopCatalog, { id: shopId, catalogId })
            : generatePath(APP_URL.shop, { id: shopId })
    }

    const handlerSuccessShopFolder = ({ parent }: TAddStoreCatalogResponse | TUpdateStoreCatalogResponse) => {
        const url = getParentCatalogUrl(parent)

        invalidateStoreCatalogAction()
            .then(() => {
                history.push(url)
            })
    }

    const handlerPreviewShopFolder = () => {
        setIsOpenModalPreview(true)
    }

    const handlerChangeShopFolder = (data: FormDataType) => {
        setDataShopFolder(data)
    }

    const handlerRemoveShopFolder = ({ parent }: TUpdateStoreCatalogProps) => {
        const url = getParentCatalogUrl(parent)

        invalidateStoreCatalogAction()
            .then(() => {
                history.push(url)
            })
    }

    const handlerErrorShopFolder = (err: TAddStoreCatalogMutationFnError | TUpdateStoreCatalogResponse) => {
        showAlertNotify({ type: 'error', message: err[0] })
    }

    function invalidateStoreCatalogAction() {
        return invalidateStoreCatalog({ storeId: shopId }, { exact: false, type: 'all' })
    }

    return (
        <>
            <ContentContainer classes={style.content} size="half">
                <PageHeader onClickBack={handlerClickBack}>
                    <Breadcrumbs items={breadcrumbs} />
                </PageHeader>
                <div className={style.formBlock}>
                    <div className={style.formHeader}>
                        {t('screen_pm_folder_title')}
                    </div>
                    <ShopFolderAction
                        classesBody={style.formBody}
                        classesFooter={style.formFooter}
                        shopFolderProps={shopFolderProps}
                        onPreview={isMobile ? handlerPreviewShopFolder : undefined}
                        onChange={handlerChangeShopFolder}
                        onRemove={handlerRemoveShopFolder}
                        onSuccess={handlerSuccessShopFolder}
                        onError={handlerErrorShopFolder}
                    />
                </div>
            </ContentContainer>
            <SideBarContainer classes={style.sidebar} position="right">
                {!isMobile && (
                    <>
                        <div className={style.sidebarTitle}>
                            {t('preview_button')}
                        </div>
                        <Block classes={cn(style.block, style.block_sidebar)}>
                            <ShopFolderPreview
                                name={dataShopFolder?.name}
                                photo={dataShopFolder?.photo}
                            />
                        </Block>
                    </>
                )}
            </SideBarContainer>

            <Modal
                isOpen={isOpenModalPreview}
                size="smallCenter"
                onClose={() => setIsOpenModalPreview(false)}
            >
                <Modal.Header isCloseButton />
                <Modal.Body>
                    <ShopFolderPreview
                        name={dataShopFolder?.name}
                        photo={dataShopFolder?.photo}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ShopFolder
