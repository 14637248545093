import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { ICity, ICountry, ILanguage } from 'interfaces'
import { FormDataType } from 'forms/UserForm/UserForm'
import { TValidationFieldRule } from 'utils/validators'
import { Button, Modal, Cropper } from 'components'
import { updateUser } from 'containers/User/user-actions'
import * as appSelectors from 'containers/App/app-selectors'
import * as userSelectors from 'containers/User/user-selectors'
import { UserForm } from 'forms'
import { AppService, TranslationService } from 'services'
import { useThunkDispatch, useForm } from 'hooks'
import { getRequestError, defaultDateFormat, showAlertNotify } from 'utils/helpers'

export type UserActionPropType = {}

const UserAction: React.FC<UserActionPropType> = () => {
    const { t } = useTranslation()
    const thunkDispatch = useThunkDispatch()

    const FORM_VALIDATION_RULES: TValidationFieldRule[] = [{
        field: 'name',
        rules: [{ rule: 'required', error: t('error_field_is_empty') }],
    }, {
        field: 'surname',
        rules: [{ rule: 'required', error: t('error_field_is_empty') }],
    }, {
        field: 'email',
        rules: [{ rule: 'email', error: t('invalid_email') }],
    }, {
        field: 'city',
        rules: [{ rule: 'required', error: t('select_city') }],
    }]

    const user = useSelector(userSelectors.user)
    const countries = useSelector(appSelectors.countries)
    const languages = useSelector(appSelectors.languages)

    const [selectedCountry, setSelectedCountry] = useState<ICountry>(user?.country)
    const [selectedCity, setSelectedCity] = useState<ICity | undefined>(user?.city)
    const [selectedLanguage, setSelectedLanguage] = useState<ILanguage>(user?.language)
    const [imageAvatar, setImageAvatar] = useState<string>() // base64
    const [imageAvatarCropped, setImageAvatarCropped] = useState<string>() // base64
    const [isShowModalAvatar, setIsShowModalAvatar] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const {
        formData,
        formErrors,
        setFormValue,
        setFormError,
        validateForm,
    } = useForm<FormDataType>({
        name: user?.name,
        surname: user?.surname,
        email: user?.email,
        birthDate: user?.birth_date ? defaultDateFormat(user.birth_date) : '',
        city: selectedCity ? selectedCity.id : undefined,
        language: selectedLanguage ? selectedLanguage.id : undefined,
        photo: user?.photo ? user.photo : '',
        gender: user?.gender,
        profileStatus: user?.profileStatus,
    }, FORM_VALIDATION_RULES)

    const handlerSubmit = () => {
        if (validateForm()) {
            updateUserAction()
        }
    }

    const handlerChangeFormData = (name: string, value: string) => {
        setFormValue(name, value)
        setFormError(name, false)
    }

    const handlerChangeCountry = (data: ICountry) => {
        setFormValue('city', undefined)
        setSelectedCountry(data)
        setSelectedCity(undefined)
    }

    const handlerChangeCity = (data: ICity) => {
        setFormValue('city', data.id)
        setSelectedCity(data)
    }

    const handlerChangeLanguage = (language: ILanguage) => {
        setFormValue('language', language.id)
        setSelectedLanguage(language)
    }

    const handlerChangePhoto = (file: File) => {
        if (file) {
            uploadImageAction(file)
        }
    }

    const handlerCropAvatar = (data: string) => {
        if (data) {
            setImageAvatarCropped(data)
        }
    }

    const handlerSetAvatar = () => {
        setFormValue('photo', imageAvatarCropped)
        setIsShowModalAvatar(false)
    }

    const handlerCloseModalCrop = () => {
        setIsShowModalAvatar(false)
    }

    function updateUserAction() {
        setIsSubmitting(true)
        thunkDispatch(updateUser(formData))
            .then((userData) => {
                if (userData) {
                    TranslationService.changeAppLanguage(userData.language.lang_short)
                }
            })
            .catch((err) => {
                showAlertNotify({ type: 'error', message: getRequestError(err) || t('update_error') })
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    function uploadImageAction(file: File) {
        AppService.uploadImageToBase64(file)
            .then((res) => {
                setImageAvatar(res)
                setIsShowModalAvatar(true)
            })
            .catch((err) => {
                showAlertNotify({ type: 'error', message: err.message })
            })
    }

    return (
        <>
            <UserForm
                data={formData}
                errors={formErrors}
                countries={countries}
                country={selectedCountry}
                languages={languages}
                language={selectedLanguage}
                city={selectedCity}
                userMemberId={user?.member_id}
                userPhones={user?.user_phones}
                isSubmitting={isSubmitting}
                onChangePhoto={handlerChangePhoto}
                onChangeCountry={handlerChangeCountry}
                onChangeLanguage={handlerChangeLanguage}
                onChangeCity={handlerChangeCity}
                onChange={handlerChangeFormData}
                onSubmit={handlerSubmit}
            />

            <Modal
                isOpen={isShowModalAvatar}
                size="medium"
                classes=""
                onClose={handlerCloseModalCrop}
            >
                <Modal.Header title={t('edit_photo')} />
                <Modal.Body>
                    <Cropper
                        className="cropperRound"
                        source={imageAvatar}
                        onCrop={handlerCropAvatar}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button text={t('install')} size="size40" onClick={handlerSetAvatar} />
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default UserAction
