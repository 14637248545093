import React from 'react'
import cn from 'classnames'

import { Block } from 'layout'
import style from './ReportsItem.module.css'

type ReportsItemPropType = {
    name: string
}

const ReportsItem: React.FC<ReportsItemPropType> = ({ name }) => {
    return (
        <Block classes={style.wrap}>
            <div className={style.title}>
                {name}
            </div>
            <div className={style.body}>
                <div className={style.bars}>
                    <div className={cn(style.bar, style.bar_h30)} />
                    <div className={cn(style.bar, style.bar_h10)} />
                    <div className={cn(style.bar, style.bar_h44)} />
                    <div className={cn(style.bar, style.bar_h30)} />
                </div>
                <div className={style.round}>
                    <div className={style.wrapArrow}>
                        <div
                            className={style.arrow}
                            dangerouslySetInnerHTML={{ __html: '&#8594;' }}
                        />
                    </div>
                </div>
            </div>
        </Block>
    )
}

export default ReportsItem
