import React, { useEffect, useState } from 'react'

import { ICalendarDay } from 'interfaces'
import { DatePeriod } from 'enums'
import {
    CalendarGridMonth,
    DateChanger,
} from 'components'
import { useCalendar } from 'hooks'
import style from './DatePicker.module.css'

type DatePickerPropType = {
    date: Date
    dateSelected?: Date
    onChange: (value: Date) => void
}

const DatePicker: React.FC<DatePickerPropType> = ({
    date,
    dateSelected,
    onChange,
}) => {
    const [dateCalendar, setDateCalendar] = useState(date)
    const [daysMonth, setDaysMonth] = useState<ICalendarDay[]>([])

    const { getMonthDays } = useCalendar()

    useEffect(() => {
        setDaysMonth(getMonthDays(dateCalendar))
    }, [dateCalendar])

    return (
        <div className={style.datepicker}>
            <DateChanger
                classes={style.changer}
                date={dateCalendar}
                period={DatePeriod.month}
                onChange={(value) => setDateCalendar(value)}
            />
            <CalendarGridMonth
                days={daysMonth}
                daysPosts={{}}
                dateSelected={dateSelected}
                onClickDay={onChange}
            />
        </div>
    )
}

export default DatePicker
