import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BREAKPOINTS } from 'config/app'
import { Button, SvgResource, Modal } from 'components'
import { useWindowResize } from 'hooks'
import { plural } from 'utils/helpers'
import style from './ReportsPresets.module.css'

type ReportsPresetsPropType = {
    classes?: string
    data?: any[] // FIXME
    onDelete: (id: number) => void
}

const MAX_ITEMS_SHOW = 3
const MORE_ITEMS_SHOW = 10

const ReportsPresets: React.FC<ReportsPresetsPropType> = ({ classes, data, onDelete }) => {
    const { t } = useTranslation()
    const [windowWidth] = useWindowResize()

    const [itemsShow, setItemsShow] = useState(MAX_ITEMS_SHOW)
    const [isOpenModalPresets, setIsOpenModalPresets] = useState(false)

    const handlerClickMore = () => {
        if (windowWidth > BREAKPOINTS.tabletLandscape) {
            setIsOpenModalPresets(true)
        } else {
            setItemsShow((prevState) => prevState + MORE_ITEMS_SHOW)
        }
    }

    const moreText = useMemo(() => {
        const text = t('reports_presets_more').replace('%s', String(MORE_ITEMS_SHOW))
        const presets = plural(MORE_ITEMS_SHOW, [t('preset_1x'), t('preset_2x'), t('preset_5x')])

        return `${text} ${presets}`
    }, [])

    return (
        <>
            <div className={classes}>
                {data && !data.length && (
                    <div className={style.empty}>
                        {t('reports_presets_empty')}
                    </div>
                )}
                {data?.slice(0, itemsShow).map((item) => (
                    <div className={style.item} key={item.id}>
                        <div className={style.title}>
                            {item.name}
                        </div>
                        <Button
                            classes={style.actionDelete}
                            styleType="transparent"
                            title={t('delete')}
                            onClick={() => onDelete(item.id)}
                        >
                            <SvgResource
                                isImgTag={false}
                                classes={style.iconTrash}
                                resourceKey="trash_can_svg"
                                width={19}
                                height={22}
                            />
                        </Button>
                    </div>
                ))}
                {!!data?.length && data.length > itemsShow && (
                    <Button
                        classes={style.actionMore}
                        styleType="text"
                        onClick={handlerClickMore}
                    >
                        {moreText}
                    </Button>
                )}
            </div>

            <Modal
                isOpen={isOpenModalPresets}
                size="smallCenter"
                onClose={() => setIsOpenModalPresets(false)}
            >
                <Modal.Header
                    classes={style.modalPresetsTitle}
                    titlePos="left"
                >
                    <span>
                        {t('reports_presets')}
                    </span>
                    <span className={style.modalPresetsTitleCount}>
                        {`(${data?.length})`}
                    </span>
                </Modal.Header>
                <Modal.Body classes={style.modalPresetsBody}>
                    <div className={style.modalPresetsBodyTitle}>
                        {t('reports_presets_available')}
                    </div>
                    {data && !data.length && (
                        <div className={style.empty}>
                            {t('reports_presets_empty')}
                        </div>
                    )}
                    {data?.map((item) => (
                        <div className={style.item} key={item.id}>
                            <div className={style.title}>
                                {item.name}
                            </div>
                            <Button
                                classes={style.actionDelete}
                                styleType="transparent"
                                title={t('delete')}
                                onClick={() => onDelete(item.id)}
                            >
                                <SvgResource
                                    isImgTag={false}
                                    classes={style.iconTrash}
                                    resourceKey="trash_can_svg"
                                    width={19}
                                    height={22}
                                />
                            </Button>
                        </div>
                    ))}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ReportsPresets
