import React, { useState } from 'react'
import { Form } from 'react-final-form'

import { FormDataType, FieldName, formDataInitial } from 'forms/PresetForm/PresetForm'
import { PresetForm } from 'forms'
import { getId } from 'utils/helpers'

type ReportsPaymentActionPropType = {
    classes?: string
    onSuccess?: (data: { id: number } & FormDataType) => void
}

const ReportsPaymentAction: React.FC<ReportsPaymentActionPropType> = ({
    classes,
    onSuccess,
}) => {
    const [initialValues] = useState<FormDataType>(formDataInitial)

    const handlerSubmit = (params: FormDataType) => {
        if (onSuccess) {
            onSuccess({ ...params, id: getId(true) })
        }

        return Promise.resolve()
    }

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handlerSubmit}
            validate={(values) => {
                const errors = {}

                if (!values[FieldName.name]) {
                    errors[FieldName.name] = true
                }

                return errors
            }}
            render={({ handleSubmit, submitting, form }) => (
                <PresetForm
                    classes={classes}
                    isSubmitting={submitting}
                    onSubmit={(e) => {
                        handleSubmit(e)
                        form.reset()
                    }}
                />
            )}
        />
    )
}

export default ReportsPaymentAction
