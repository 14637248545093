import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import cn from 'classnames'

import { APP_URL } from 'config/app'
import * as appSelectors from 'containers/App/app-selectors'
import { SvgResource, CustomSelect } from 'components'
import { TranslationService } from 'services'
import style from './MenuNavMainPublic.module.css'

const MenuNavMainPublic: React.FC = () => {
    const { t, i18n } = useTranslation()

    const languages = useSelector(appSelectors.languages)

    const lang = useMemo(() => i18n.language, [i18n.language])

    const handlerClickLanguage = (id: number) => {
        const language = languages.find((item) => item.id === id)

        if (language) {
            TranslationService.changeAppLanguage(language.lang_short)
        }
    }

    return (
        <ul className={style.menu}>
            <li className={style.menuItem}>
                <NavLink
                    className={cn(style.link, style.link_icon)}
                    to={APP_URL.support}
                    title={t('ui_support_create_ticket_title')}
                >
                    <SvgResource
                        isImgTag={false}
                        resourceKey="ic_support_v2_svg"
                        width={32}
                        height={32}
                    />
                </NavLink>
            </li>

            <li className={style.menuItem}>
                <CustomSelect.Select>
                    <CustomSelect.Field classes={style.selectField}>
                        {lang}
                    </CustomSelect.Field>
                    <CustomSelect.Options>
                        {languages.map((item) => (
                            <CustomSelect.Option
                                id={item.id}
                                classes={cn(
                                    style.selectOption,
                                    {
                                        [style.selectOption_active]: item.lang_short === lang,
                                    },
                                )}
                                key={item.id}
                                onClick={handlerClickLanguage}
                            >
                                {item.lang_short}
                            </CustomSelect.Option>
                        ))}
                    </CustomSelect.Options>
                </CustomSelect.Select>
            </li>
        </ul>
    )
}

export default MenuNavMainPublic
