import React from 'react'
import cn from 'classnames'

import style from './Block.module.css'

type BlockPropType = {
    classes?: string
    styles?: React.CSSProperties
}

const Block: React.FC<BlockPropType> = ({
    children,
    classes,
    styles,
}) => {
    return (
        <div className={cn(style.block, classes)} style={styles}>
            {children}
        </div>
    )
}

export default Block
