import React, {
    useMemo,
    useRef,
    useState,
    createElement,
} from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { FormDataType as ReportsPaymentFormDataType, FieldName } from 'forms/ReportsProductForm/ReportsProductForm'
import { FormDataType as PresetFormDataType } from 'forms/PresetForm/PresetForm'
import { TableConfiguration } from 'containers/Reports/components/ReportsTableView/ReportsTableView'
import { APP_URL } from 'config/app'
import { ContentContainer, Block } from 'layout'
import { useFetchCurrencies } from 'containers/App/hooks'
import { ReportsPresets, ReportsTableView } from 'containers/Reports/components'
import { useFetchStores } from 'containers/Store/hooks'
import {
    PageHeader,
    Popover,
    MenuList,
    SvgResource,
    Tooltip,
} from 'components'
import { ReportsProductAction, PresetAction } from 'form-actions'
import { dateTimeFormat, numberFormat } from 'utils/helpers'
import styleForm from 'styles/modules/form.module.css'
import style from './ReportsProduct.module.css'

enum ReportSort {
    byName = 'sort_by_name',
    byVendor = 'sort_by_vendor',
    bySales = 'sort_by_sales',
    byAvailable = 'sort_by_visibility',
}

const reportSortActionIcon = {
    [ReportSort.byName]: 'ic_arrow_down_svg',
    [ReportSort.byVendor]: 'ic_arrow_down_svg',
    [ReportSort.bySales]: 'ic_arrow_down_svg',
    [ReportSort.byAvailable]: 'ic_arrow_down_svg',
}

const reportSortActionIconParams = {
    [ReportSort.byName]: ['down'],
    [ReportSort.byVendor]: ['down'],
    [ReportSort.bySales]: ['down', 'up'],
    [ReportSort.byAvailable]: ['down', 'up'],
}

const randomInteger = (min: number, max: number) => {
    const rand = min + Math.random() * (max + 1 - min)
    return Math.floor(rand)
}

const createTableColValue = (key: string, title: string, value: string) => {
    return createElement('div', { className: style.tableCol, key }, [
        createElement('div', { className: style.tableColTitle, key: `${title}-${value}-1` }, title),
        createElement('div', { className: style.tableColValue, key: `${title}-${value}-2` }, value),
    ])
}

const ReportsProduct: React.FC = () => {
    const { t, i18n } = useTranslation()
    const history = useHistory()

    const reportsRef = useRef<HTMLDivElement>(null)
    const [reportsParams, setReportsParams] = useState<ReportsPaymentFormDataType>()
    const [reportsData, setReportsData] = useState<{
        name: string
        vendor: string
        sales: number
        available: 0 | 1
    }[]>()
    const [activeReportSort, setActiveReportSort] = useState<ReportSort>(ReportSort.byName)

    const [presetsData, setPresetsData] = useState([
        { id: 1, name: 'Таблетосы' },
        { id: 2, name: 'Чай кофе потанцуем' },
        { id: 3, name: 'Чай кофе леденцы' },
        { id: 4, name: 'preset 4' },
        { id: 5, name: 'preset 5' },
        { id: 6, name: 'preset 6' },
        { id: 7, name: 'preset 7' },
        { id: 8, name: 'preset 8' },
    ])

    const { data: dataStores } = useFetchStores()

    const { data: dataCurrencies } = useFetchCurrencies(undefined, { enabled: !!reportsParams })

    const reportTitle = useMemo(() => {
        const dateFrom = reportsParams && reportsParams[FieldName.dateFrom]
        const dateTo = reportsParams && reportsParams[FieldName.dateTo]

        if (dateFrom && dateTo) {
            const storeName = dataStores ? dataStores.find((item) => item.id === reportsParams[FieldName.store])?.name : ''
            const period = t('period_range')
                .replace('%s', dateTimeFormat(dateFrom, i18n.language))
                .replace('%s', dateTimeFormat(dateTo, i18n.language))
            const subText = reportsParams[FieldName.isCumulative] ? `. ${t('reports_cumulative_total')}` : ''

            return `${t('reports_store')} ${storeName} ${period} ${subText}`
        }

        return ''
    }, [reportsParams])

    const reportCurrency = useMemo(() => {
        return dataCurrencies && !!reportsParams?.currency
            ? dataCurrencies.find((item) => item.id === reportsParams.currency)?.showCode
            : undefined
    }, [dataCurrencies, reportsParams])

    const reportOrderSum = useMemo(() => {
        return numberFormat(randomInteger(100000, 200000))
    }, [reportsData])

    const reportTableConfig = useMemo<TableConfiguration | undefined>(() => {
        if (!reportsData) {
            return undefined
        }

        return {
            data: {
                labels: [
                    createElement('div', { className: style.tableCol, key: 'screen_pm_name_title' }, [t('screen_pm_name_title')]),
                    createElement('div', { className: style.tableCol, key: 'screen_pm_vendor_code_title' }, [t('screen_pm_vendor_code_title')]),
                    createElement('div', { className: style.tableCol, key: 'Продано' }, ['Продано']), // FIXME KW-293
                    createElement('div', { className: style.tableCol, key: 'screen_pm_goods_spinner_description_hint' }, [t('screen_pm_goods_spinner_description_hint')]),
                ],
                dataset: {
                    data: reportsData.map((item) => {
                        return [
                            createTableColValue('screen_pm_name_title', t('screen_pm_name_title'), String(item.name)),
                            createTableColValue('screen_pm_vendor_code_title', t('screen_pm_vendor_code_title'), String(item.vendor)),
                            createTableColValue('Продано', 'Продано', String(item.sales)),
                            createTableColValue(
                                'screen_pm_goods_spinner_description_hint',
                                t('screen_pm_goods_spinner_description_hint'),
                                String(item.available ? 'Доступный' : 'Недоступный'), // FIXME KW-293
                            ),
                        ]
                    }),
                },
            },
        }
    }, [reportsData])

    const handlerSuccessPreset = (value: { id: number } & PresetFormDataType) => {
        setPresetsData((prevState) => {
            return !prevState.find((item) => item.name === value.name)
                ? [value, ...prevState]
                : prevState
        })
    }

    const handlerDeletePreset = (value: number) => {
        setPresetsData((prevState) => {
            return prevState.filter((item) => item.id !== value)
        })
    }

    const handlerSuccessReportsPayment = (values: ReportsPaymentFormDataType) => {
        const today = new Date()
        const dateFrom = new Date(values[FieldName.dateFrom])
        const dateTo = new Date(values[FieldName.dateTo])
        const days = Math.ceil(Math.abs(dateTo.getTime() - dateFrom.getTime()) / (1000 * 3600 * 24))

        setReportsParams(values)
        // FIXME fake data KW-293
        setReportsData(Array(days).fill({}).reduce((acc, item, index) => {
            const date = new Date(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate() + index)
            const name = 'Черный кофе с императорским женьшенем'
            const vendor = `ХХХ${randomInteger(100, 999)}ххх`
            const sales = randomInteger(5, 40)
            const available = randomInteger(0, 1)

            return date <= today
                ? [
                    ...acc,
                    {
                        id: index + 1,
                        date,
                        name,
                        vendor,
                        sales,
                        available,
                    },
                ]
                : acc
        }, []))
    }

    const handlerClickBack = () => {
        history.push(APP_URL.reports)
    }

    return (
        <ContentContainer size="three-quarters">
            <div className={style.innerWrap}>
                <div className={style.contentInner}>
                    <PageHeader
                        title={t('reports_product')}
                        onClickBack={handlerClickBack}
                    />
                    <Block classes={cn(style.block, style.block_form)}>
                        <div className={cn(style.blockHeader, style.blockHeader_bordered)}>
                            <div className={style.blockTitle}>
                                {t('reports_filter')}
                            </div>
                        </div>
                        <ReportsProductAction
                            classes={style.blockBody}
                            onSuccess={handlerSuccessReportsPayment}
                        />
                    </Block>
                </div>
                <div className={style.sidebarInner}>
                    <Block classes={cn(style.block, style.block_sidebar)}>
                        <div className={cn(style.blockHeader, style.blockHeader_bordered, style.blockHeader_row)}>
                            <div className={style.blockTitle}>
                                {t('reports_presets')}
                            </div>
                            <div className={style.blockCount}>
                                {`(${presetsData.length})`}
                            </div>
                        </div>
                        <div className={style.blockBody}>
                            <div className={styleForm.fieldHead}>
                                {t('reports_presets_available')}
                            </div>
                            <ReportsPresets
                                classes={style.presets}
                                data={presetsData}
                                onDelete={handlerDeletePreset}
                            />
                            <PresetAction
                                onSuccess={handlerSuccessPreset}
                            />
                        </div>
                    </Block>
                </div>
            </div>

            {reportsData && (
                <Block classes={cn(style.block, style.block_reports)}>
                    <div className={cn(
                        style.blockHeader,
                        style.blockHeader_row,
                        style.blockHeader_gap,
                        style.blockHeader_gray,
                    )}
                    >
                        <div className={style.reportsHeader}>
                            <div className={cn(
                                style.blockTitle,
                                { [style.blockTitle_flex]: reportsParams?.isCumulative },
                            )}
                            >
                                {reportTitle}
                                {!!reportsParams?.isCumulative && (
                                    <Tooltip
                                        classes={style.blockTitleTooltip}
                                        position="right"
                                        tooltip={t('reports_cumulative_total')}
                                    >
                                        <Tooltip.TriggerInfo />
                                    </Tooltip>
                                )}
                            </div>
                            <div className={style.reportsCurrency}>
                                {`${t('order_sum')}: `}
                                {`${reportOrderSum} `}
                                {reportCurrency || (
                                    // FIXME ? default currency is rub: KW-293
                                    <span dangerouslySetInnerHTML={{ __html: '&#8381;' }} />
                                )}
                            </div>
                        </div>
                        <div className={style.reportsActions} ref={reportsRef}>
                            <Popover
                                classes={style.reportsAction}
                                classesBody={style.reportsActionContent}
                                isShowArrow={false}
                                trigger={(
                                    <SvgResource
                                        isImgTag={false}
                                        classes={style.iconSortAction}
                                        resourceKey="ic_sort_svg"
                                        width={20}
                                        height={20}
                                    />
                                )}
                                position="bottom"
                                side="left"
                            >
                                <MenuList classes={style.reportsActionList}>
                                    {Object.entries(reportSortActionIcon).map(([key, value]) => (
                                        <MenuList.Item
                                            key={key}
                                            onClick={() => setActiveReportSort(key as ReportSort)}
                                        >
                                            <span
                                                className={cn(
                                                    style.reportsActionText,
                                                    { [style.reportsActionText_active]: activeReportSort === key },
                                                )}
                                            >
                                                {t(key)}
                                            </span>
                                            <div className={style.reportSortIcons}>
                                                {(reportSortActionIconParams[key] as string[]).map((item) => (
                                                    <SvgResource
                                                        isImgTag={false}
                                                        classes={cn(
                                                            style.iconSort,
                                                            style[`iconSort_${item}`],
                                                            { [style.iconSort_active]: activeReportSort === key },
                                                        )}
                                                        resourceKey={value}
                                                        width={10}
                                                        height={14}
                                                    />
                                                ))}
                                            </div>
                                        </MenuList.Item>
                                    ))}
                                </MenuList>
                            </Popover>
                        </div>
                    </div>
                    <div className={style.reports}>
                        <div className={style.reportsTableView}>
                            <ReportsTableView
                                config={reportTableConfig}
                            />
                        </div>
                    </div>
                </Block>
            )}
        </ContentContainer>
    )
}

export default ReportsProduct
